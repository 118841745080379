<template>
    <div>
        <div class="wrapper innerBg">
        <!--header-->
        <div class="header">
          <div class="pagesize">
            <div class="headerNav">
              <router-link class="headerlogo" to="/index"><img src="./images/logo.png" alt="">臺北市士林區公所能源管理系統</router-link>
              <div class="state web">
                <div class="circle"></div>通訊正常
              </div>
              <ul class="headerNav_menu web">
            <li><router-link to="/index">首頁</router-link></li>
            <li><router-link to="./public" >公共用電</router-link></li>
            <li><router-link to="./floor" >樓層用電</router-link></li>
            <li><router-link to="./ice" class="on">冰水主機</router-link></li>
            <li><router-link to="./solar">光電系統</router-link></li>
            <li><router-link to="./download" >下載專區</router-link></li>
              </ul>
              <div class="menuic mobile btn_menu">
                <img class="btn_pic01 btn_menuck" src="./images/icon_menu.png" alt="">
                <img class="btn_pic02 btn_menuck" src="./images/icon_close.png" alt="">
              </div>
            </div>
          </div>
          <!--小網menu-->
          <div class="open_nav">
            <ul>
              <li class="m_nav"><router-link to="/index">首頁</router-link></li>
              <li class="m_nav"><router-link to="./public">公共用電</router-link></li>
              <li class="m_nav"><router-link to="./floor">樓層用電</router-link></li>
              <li class="m_nav"><router-link to="./ice">冰水主機</router-link></li>
              <li class="m_nav"><router-link to="./electricity">光電系統</router-link></li>
              <li class="m_nav"><router-link to="./download">下載專區</router-link></li>
            </ul>
            <div class="state">
              <div class="circle"></div>通訊正常
            </div>
          </div>
        </div>
        <!--內容開始-->
        <div class="contain">
          <div class="pagesize">
            <div class="main">
              <div class="lsDate">最後更新時間 {{upDateTime}}</div>
              <div class="leftSec">
                <div class="sec">
                  <p>即時用電量</p>
                  <div class="tR">
                    <p class="num">{{iceKw}}<span>kW</span></p>
                  </div>
                  <div class="graphBorder"></div>
                  <p>本日累積用量</p>
                  <div class="tR">
                    <p class="num">{{handleNum(iceKwToday)}}<span>度</span></p>
                  </div>
                </div>
                <div class="iceSec mgb">
                  <div class="iceMac off">
                    <p class="iceTit">一號冰機(未連線)</p>
                    <!-- <img :src="adamOn" v-if="adOneONOff()">
                    <img :src="adamOff" v-else> -->
                    <p class="iceNum">1</p>
                  </div>
                  <div class="iceMac">
                    <p class="iceTit">二號冰機</p>
                    <img :src="adamOn" v-if="adamOnOff.adam2IsOn">
                    <img :src="adamOff" v-else>
                    <p class="iceNum">2</p>
                  </div>
                  <div class="iceMac">
                    <p class="iceTit">三號冰機</p>
                    <img :src="adamOn" v-if="adamOnOff.adam3IsOn">
                    <img :src="adamOff" v-else>
                    <p class="iceNum">3</p>
                  </div>
                </div>
                <div class="graph graphM">
                  <p>即時空調用電佔比</p>
                  <div class="air">
                    <Highcharts :options="icePieoptions" ref="highchart" />
                    <!-- <img src="./images/graph8.png"> -->
                  </div>
                  <div class="airPe">
                    <div class="airPe_main">
                      <div class="airCircle"></div>
                      <p class="airItem">空調</p>
                      <p class="airNum">{{icePercent}}<span>%</span></p>
                    </div>
                    <div class="airPe_other">
                      <div class="airCircle"></div>
                      <p class="airItem">其他</p>
                      <p class="airNum">{{meterPercent}}<span>%</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rightSec">
                <div class="graph graphM mgb">
                  <p>用電歷史資訊</p>
                  <div class="graphBar">
                    <div class="interval">
                      <input type="checkbox" id="c1" name="yesOrNo" v-model="selectCheck">
                      <label for="c1">選擇區間<span></span></label>
                    </div>
                    <div class="btn_date">
                    <a @click="dateTypeSelect('date')" :class="{ dateActive: DateType === 'date'}">日</a>
                    <a @click="dateTypeSelect('month')" :class="{ dateActive: DateType === 'month'}">月</a>
                    <a @click="dateTypeSelect('year')" :class="{ dateActive: DateType === 'year'}">年</a>
                    </div>
                    <div class="interCa">
                      <div class="inputCa wM">
                        <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" :type="DateType" valueType="format"></date-picker>
                      </div>
                      <p class="dib" v-if="selectCheck">至</p>
                      <div class="inputCa wM" v-if="selectCheck">
                        <date-picker style="width:auto" placeholder="起始日期" v-model="endDate" :type="DateType" valueType="format"></date-picker>
                      </div>
                    </div>
                    <div class="btn_confirm dib">
                      <a @click="historyQuery(DateType)">確認</a>
                    </div>
                  </div>
                  <Highcharts :options="selectIceChart" ref="highchart" />
                  <!-- <img class="web" src="./images/graph6.png">
                  <img class="mobile" src="./images/graph6_m.png"> -->
                </div>
                <div class="graph graphM">
                  <div class="infoOpt">
                    <p>冰水主機降載設定</p>
                    <div class="btn_confirm dib">
                      <a @click="getAdamStatus">更新</a>
                    </div>
                  </div>
                  <div class="iceDate" v-show="true">
                    <div class="iceState">
                      <p class="day">冰水主機二號機</p>
                      <div>
                        <img :src="adamOn" v-if="adamOnOff.adam2IsOn" style="width:45px;">
                        <img :src="adamOff" v-else style="width:45px">
                      </div>
                      <div class="interval">
                      </div>
                      <span>
                        <p class="set" v-if="adamOneStatus">降載狀態: {{adamOneStatus.deloading ? '降載中' : '關閉'}}</p>
                        <p class="set" v-if="adamOneStatus.offsetEnd !== '未設定' && adamOneStatus.offsetStart !== '未設定'">已設定 {{secToTime(adamOneStatus.offsetStart)}} 至 {{secToTime(adamOneStatus.offsetEnd)}}</p>
                        <p class="unset" v-else>未設定</p>
                      </span>
                    </div>
                    <div class="interCa">
                      <date-picker  v-model="adamSetBeginTime.today" type="time" placeholder="開始時間" format="HH:mm" value-type="format" :minute-step="15" :disabled-time="disabledTime"></date-picker>
                    </div>
                    <p class="dib pdb">至</p>
                    <div class="interCa">
                      <date-picker v-model="adamSetEndTime.today" type="time" placeholder="結束時間" format="HH:mm" value-type="format" :minute-step="15" :disabled-time="disabledTime"></date-picker>
                    </div>
                  </div>
                    <div class="iceBtn" v-show="true">
                      <div class="btn_ice gray">
                        <a @click="deleteadams(2)">清除設定</a>
                      </div>
                      <div class="btn_ice">
                        <a @click="newcontrollAdams(adamSetBeginTime.today, adamSetEndTime.today, 2, adamOnOff.adam2IsOn)">儲存設定</a>
                      </div>
                    </div>
                  <div class="graphBorder"></div>
                  <div class="iceDate" v-show="true">
                    <div class="iceState">
                      <p class="day">冰水主機三號機</p>
                      <div>
                        <img :src="adamOn" v-if="adamOnOff.adam3IsOn" style="width:45px;">
                        <img :src="adamOff" v-else style="width:45px">
                      </div>
                      <div class="interval">
                      </div>
                      <span>
                        <p class="set" v-if="adamTwoStatus">降載狀態: {{adamTwoStatus.deloading ? '降載中' : '關閉'}}</p>
                        <p class="set" v-if="adamTwoStatus && adamTwoStatus.offsetEnd !== '未設定' && adamTwoStatus.offsetStart !== '未設定'">已設定 {{secToTime(adamTwoStatus.offsetStart)}} 至 {{secToTime(adamTwoStatus.offsetEnd)}}</p>
                        <p class="unset" v-else>未設定</p>
                      </span>
                    </div>
                    <div class="interCa">
                      <date-picker  v-model="adamSetBeginTime.nextDay" type="time" placeholder="開始時間" format="HH:mm" value-type="format" :minute-step="15" :disabled-time="disabledTime"></date-picker>
                    </div>
                    <p class="dib pdb">至</p>
                    <div class="interCa">
                      <date-picker v-model="adamSetEndTime.nextDay" type="time" placeholder="結束時間" format="HH:mm" value-type="format" :minute-step="15" :disabled-time="disabledTime"></date-picker>
                    </div>
                  </div>
                  <div class="iceBtn" v-show="true">
                    <div class="btn_ice gray">
                      <a @click="deleteadams(3)">清除設定</a>
                    </div>
                    <div class="btn_ice">
                      <a @click="newcontrollAdams(adamSetBeginTime.nextDay, adamSetEndTime.nextDay, 3, adamOnOff.adam3IsOn)">儲存設定</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--footer-->
        <footer>
          <div class="pagesize">
            <div class="ftLogo"><img src="./images/logo2.png" alt="">
            </div>
            <div class="copyright">
              Copyright © All Rights Reserved.
            </div>
          </div>
        </footer>
      </div>
    </div>
</template>

<style scoped>
  @import './css/global.css';
  a {
    cursor: pointer;
  }
  .dateActive {
    background-color: #128771;
    color: white
  }
  .inputW {
    width: 170px;
  }
</style>

<script>
import importJQ from './js/common'
import $ from 'jquery'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VueCookies from 'vue-cookies'
import Vue from 'vue'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import { dbDataHandle } from '../store/function/index'
import { DateTime } from 'luxon'
import adamOn from './images/icon_on.png'
import adamOff from './images/icon_off.png'
export default {
  components: { DatePicker },
  data () {
    return {
      adamOn: adamOn,
      adamOff: adamOff,
      lastDocice: '',
      iceKw: '',
      iceKwToday: '',
      selectCheck: false,
      DateType: 'date',
      iceToday: DateTime.local().toFormat('yyyy-MM-dd'),
      iceTommorw: DateTime.local().plus({ days: 1 }).toFormat('yyyy-MM-dd'),
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      endDate: DateTime.local().toFormat('yyyy-MM-dd'),
      adamSetBeginTime: {
        // today: DateTime.local().toFormat('HH:mm'),
        // nextDay: DateTime.local().toFormat('HH:mm')
        today: '',
        nextDay: ''
      },
      adamSetEndTime: {
        today: '',
        nextDay: ''
      },
      iceNum: 2,
      adamOneStatus: {},
      adamTwoStatus: {},
      currentSolarKw: '',
      solarDayKwh: '',
      solarMonKwh: '',
      accCO2Reduction: '',
      upDateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm'),
      adamOnOff: '',
      SolarEtodayChart: {
        chart: {
          type: 'column',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '發電量(度)',
          data: [],
          color: '#f7942f'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      selectIceChart: {
        chart: {
          type: 'line',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '用電量 (度)',
          data: [],
          color: '#F15757'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      icePieoptions: {
        chart: {
          backgroundColor: null,
          type: 'pie',
          height: 150,
          width: 150,
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          pie: {
            innerSize: 50,
            depth: 10,
            colors: ['#F15757', '#A2A2A2'],
            dataLabels: {
              enabled: false
            }
          }
        },
        series: [{
          name: '',
          data: [
            ['空調', 75],
            ['其他', 25]
          ]
        }]
      },
      icePercent: '',
      meterPercent: ''
    }
  },
  methods: {
    ...mapActions([
      'getdayData',
      'getHourData',
      'getDayInterval',
      'getMonData',
      'getLastMerged',
      'getMonInterval'
    ]),
    adamSetTime () {
      console.log(this.adamSetBeginTime)
    },
    disabledTime (date) {
      return date.getHours() < new Date().getHours()
    },
    dayTimeToSec (time) {
      const hourToSec = Number(time.slice(0, 2)) * 3600
      const minToSec = Number(time.slice(-2)) * 60
      const totalSec = hourToSec + minToSec
      return totalSec
    },
    secToTime (sec) {
      if (sec) {
        const hour = parseInt(sec / 3600)
        const min = (sec % 3600) / 60
        return `${('0' + hour).slice(-2)} : ${('0' + min).slice(-2)}`
      }
    },
    async newcontrollAdams (begin, end, iceNum, acON) {
      const str = iceNum === 2 ? '冰水主機二號機' : '冰水主機三號機'
      if (!acON) {
        Vue.swal(str + ' ' + '未開啟')
        return
      }
      if (!begin || !end) {
        Vue.swal('請選擇時間')
        return
      }
      const offsetStart = this.dayTimeToSec(begin)
      const offsetEnd = this.dayTimeToSec(end)
      const token = VueCookies.get('Authorization')
      const res = await axios.post('/api/ems/shilin/controlladams',
        {
          iceNum: iceNum,
          offsetStart,
          offsetEnd
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (res.data.success) {
        Vue.swal(str + ' ' + '儲存設定')
        this.getAdamStatus()
      } else {
        Vue.swal(str + ' ' + '控制失敗')
      }
    },
    async deleteadams (iceNum) {
      const token = VueCookies.get('Authorization')
      const deleteAdams = await axios.delete('/api/ems/shilin/deleteadams',
        {
          data: { iceNum },
          headers: { Authorization: `bearer ${token}` }
        })
      if (deleteAdams.data.success) {
        const str = iceNum === 2 ? '冰水主機二號機' : '冰水主機三號機'
        Vue.swal(str + ' ' + '已清除設定')
        this.getAdamStatus()
      }
    },
    async getSolarHourChart () {
      await this.getHourData()
      for (let i = 0; i < this.EhourData.data.length; i++) {
        if (this.EhourData.data[i].detail.public.iceKwh) {
          this.SolarEtodayChart.xAxis.categories.push(this.EhourData.data[i].hhmm)
          this.SolarEtodayChart.series[0].data.push(this.handleNum(this.EhourData.data[i].detail.public.iceKwh))
        }
      }
    },
    async getCurrentSolarKw () {
      await this.getLastMerged()
      this.currentSolarKw = this.handleNum(this.lastCurrentData.data.detail.RE_SOLAR[0].genkW)
    },
    async culSolarMonKwh () {
      const date = {
        beginDate: DateTime.local().toFormat('yyyy-MM'),
        endDate: DateTime.local().toFormat('yyyy-MM')
      }
      await this.getMonInterval(date)
      this.solarMonKwh = this.handleNum(this.MonInterData.data[0].detail.public.pv)
    },
    async getAdamStatus () {
      this.statusLoading = false
      const token = VueCookies.get('Authorization')
      const adamStatus = await axios.post('/api/ems/shilin/adamsstatus',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (adamStatus.data.success) {
        this.adamOneStatus = adamStatus.data.data[0]
        this.adamTwoStatus = adamStatus.data.data[1]
        this.statusLoading = true
      }
    },
    async getAccCO2Reduction () {
      const token = VueCookies.get('Authorization')
      const accCO2Reduction = await axios.post('/api/ems/shilin/accCO2Reduction',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.accCO2Reduction = this.handleNum(accCO2Reduction.data.data, 0)
    },
    async getlastDocice () {
      const token = VueCookies.get('Authorization')
      const lastDocice = await axios.post('/api/ems/shilin/lastDocice',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (lastDocice.data.success) {
        this.iceKw = lastDocice.data.data.iceKw
        this.adamOnOff = {
          adam1IsOn: lastDocice.data.data.adam1IsOn,
          adam2IsOn: lastDocice.data.data.adam2IsOn,
          adam3IsOn: lastDocice.data.data.adam3IsOn
        }
        this.icePercent = this.handleNum(lastDocice.data.data.airConditionerDemand) >= 100 ? 100 : this.handleNum(lastDocice.data.data.airConditionerDemand)
        this.meterPercent = this.handleNum(100 - lastDocice.data.data.airConditionerDemand) >= 100 ? 100 : this.handleNum(100 - lastDocice.data.data.airConditionerDemand)
        this.icePieoptions.series[0].data = []
        this.icePieoptions.series[0].data.push(['空調', this.icePercent])
        this.icePieoptions.series[0].data.push(['其他', this.meterPercent])
      }
    },
    async getIceKwhToday () {
      const token = VueCookies.get('Authorization')
      const iceKwhToday = await axios.post('/api/ems/shilin/iceKwhToday',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (iceKwhToday.data.success) {
        this.iceKwToday = this.handleNum(iceKwhToday.data.data)
      }
    },
    handleNum (val, num) {
      return dbDataHandle(val, num)
    },
    getIceChart (arr) {
      this.selectIceChart.series[0].data = []
      this.selectIceChart.xAxis.categories = []
      for (let i = 0; i < arr.data.length; i++) {
        if (arr.data[i].hhmm) {
          this.selectIceChart.xAxis.categories.push(arr.data[i].hhmm)
          this.selectIceChart.series[0].data.push(this.handleNum(arr.data[i].detail.public.iceKwh))
        } else if (arr.data[i].date) {
          this.selectIceChart.xAxis.categories.push(arr.data[i].date)
          this.selectIceChart.series[0].data.push(this.handleNum(arr.data[i].detail.public.iceKwh))
        }
      }
    },
    dateTypeSelect (str) {
      this.DateType = str
      if (str === 'date') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM-dd')
        this.endDate = DateTime.local().toFormat('yyyy-MM-dd')
      }
      if (str === 'month') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM')
        this.endDate = DateTime.local().toFormat('yyyy-MM')
      }
      if (str === 'year') {
        this.beginDate = DateTime.local().toFormat('yyyy')
        this.endDate = DateTime.local().toFormat('yyyy')
      }
    },
    async historyQuery (type) {
      if (!this.selectCheck) {
        if (type === 'date') {
          await this.getHourData(this.beginDate)
          this.getIceChart(this.EhourData)
        } else if (type === 'month') {
          await this.getdayData(this.beginDate)
          this.getIceChart(this.EdayData)
        } else if (type === 'year') {
          await this.getMonData(this.beginDate)
          this.getIceChart(this.EmonData)
        }
      } else {
        const date = {
          beginDate: this.beginDate,
          endDate: this.endDate
        }
        if (type === 'date') {
          await this.getDayInterval(date)
          this.getIceChart(this.InterData)
        } else if (type === 'month') {
          await this.getMonInterval(date)
          this.getIceChart(this.MonInterData)
        }
      }
    }
  },
  async mounted () {
    await importJQ()
    this.getSolarHourChart()
    this.getCurrentSolarKw()
    this.culSolarMonKwh()
    this.getAccCO2Reduction()
    this.getlastDocice()
    this.getIceKwhToday()
    this.getAdamStatus()
    this.historyQuery(this.DateType)
    $(function () {
      $(document).ready(function () {
        var $win = $(window)
        $win.bind('resize', function () {
          try {
            $('.contain').css('min-height', $win.height() - $('footer').outerHeight() - $('.contain').offset().top)
          } catch (err) {}
        }).resize()
      })
    })
  },
  computed: {
    ...mapState([
      'EhourData',
      'EdayData',
      'EmonData',
      'lastCurrentData',
      'InterData',
      'MonInterData'
    ])
  }
}
</script>
